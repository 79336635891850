import React from "react"
import LinkPNG from "../../images/link.png"

export default ({data, bulletLimit}) => (
<div className="row col-12 mx-auto section">
        <hr/>
        <h3 className="col-12 px-0 text-center" style={data[0]?.link ? {marginBottom: "15px"} : {marginBottom: "20px"} }>INTERESTS</h3>
        <div className="col-12 px-0">
            <ul className="dash">
                {data.map((interest, index, interests) =>  index >= bulletLimit ? null : interest.link !== undefined ? 
                    <a href={interest.link} className="d-inline-flex">
                        <li style={interests[index+1]?.link === undefined ? {minWidth: "100%"} : {minWidth: "100%", marginBottom:0}} key={interest.label}>
                            {interest.label}
                        </li> 
                        <img className="my-auto mr-2" alt="Link icon" src={LinkPNG} style={{width: "14px", height: "14px", opacity: 0.3}}/>
                    </a>
                    :
                    <li style={interests[index+1]?.link === undefined ? {minWidth: "100%"} : {minWidth: "100%", marginBottom:0}} key={interest.label}>
                        {interest.label}
                    </li> 
                )}
            </ul>
        </div>
    </div>
)