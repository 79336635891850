import React from "react"

export const SkillItem = ({skill}) => (
  <div className="col-6">
    <p className="skills-body">{skill}</p>
  </div>
)

export default ({data}) => {

  const totalSkills = data.backEnd.length;

  let skills = [];

  for(let i=0; i<totalSkills; i++) {
    skills.push(data.backEnd[i].type);
    skills.push(data.frontEnd[i].type);
  }

  return (
    <div className="row col-12 mx-auto section">
      <hr/>
      <h3 className="text-center col-12">SKILLS</h3>
      {skills.map((skill) => (
        <SkillItem 
          skill={skill}
          key={skill}
        />
      ))}
    </div>
  )
}