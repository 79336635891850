import React from "react"

export const EducationEx = ({data}) => (
    <div className="row col-12 mx-auto section">
        <hr/>
        <h3 className="col-12 px-0 text-center">EDUCATION</h3>
        <div className="col-12 px-0 text-center">
            <p style={{fontSize:"14px"}}><b>{data[0].name}</b></p>
            <p style={{lineHeight:"100%"}}>{data[0].subject + " " + data[0].level}</p>
            <p style={{fontSize:"9pt", lineHeight:"170%"}}><i>{data[0].dateRangeShort}</i></p>
            <p style={{fontSize:"9pt"}}>{data[0].label}</p>
        </div>
    </div>
)

export const EducationEd = ({data, bulletLimit}) => (
    <div className="row col-12 mx-auto section">
        <hr/>
        <h3 className="col-12 px-0 mb-2">EDUCATION</h3>
        <div className="row col-12 mx-auto px-0">
            <p className="d-flex col-6 px-0 pt-2" style={{fontSize:"14pt"}}><b>{data[0].name}</b></p>
            <p className="col-6 px-0 d-flex justify-content-end pt-2 mt-1" style={{fontSize:"10pt", transform: "translate(0, 2px)"}}><b>{data[0].dateRange}</b></p>
            <p className="d-flex col-6 px-0" style={{fontSize:"11pt"}}><b>{data[0].events[0].title}</b></p>
          <p className="col-6 px-0 d-flex justify-content-end" style={{fontSize:"10pt", transform: "translate(0, 2px)"}}><b>First-Class Honours (84% Avg)</b></p>
            <ul className="dash pt-2">
                <li><b>Relevant modules:</b> {data[0].relevantModules}</li>
                <li><b>Final year project:</b> {data[0].finalYearProject}</li>
                {data[0].longDesc.map((desc, i) => (i < bulletLimit ? <li key={data[0].name.replace(" ", "-")+"-desc-"+i} >{desc}</li> : null ))}
            </ul>
        </div>
    </div>
)