import React from "react"

export default ({data}) => (
  <div className="row col-12 m-0 title">
    <div className="col-1">
      <h2 className="d-inline float-right">{data.name.charAt(0).toUpperCase()}</h2>
      <div className="note"><p className="contact-text">This cv was made entirely using ReactJS!</p></div>
    </div>
    <div className="col-11 title-border pb-3 mb-2">
      <div className="col-12 px-0 d-flex justify-content-between">
        <h2>{data.name.split(" ").pop().charAt(0).toUpperCase()}</h2>
        <h1>{data.name.toUpperCase()}</h1>
      </div>
      <div className="col-12 px-0 d-flex justify-content-end">
        <h4>{data.title.toUpperCase()}</h4>
      </div>
      <div className="col-12 px-0 d-flex justify-content-end">
        <p className="pr-1 border-right contact-text"><a href={data.website}>{data.websiteShort}</a></p>
        <p className="pl-1 contact-text">{data.email}</p>
      </div>
    </div>
  </div>
)
