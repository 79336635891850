import React from "react"
import LinkPNG from "../../images/link.png"
import { StaticQuery, graphql } from "gatsby"


export const Project = ({data, bulletLimit, projectNum}) => (
    <div className="row col-12 px-0 mx-auto">
        <div className="tags">
            <div className="d-inline-block">
                <a href={"https://jake-t.codes" + data.node.frontmatter.path} className="d-flex">
                    <p className="pr-1" style={{fontSize:"14pt"}}><b>{data.node.frontmatter.title}</b></p>
                    <img className="my-auto mr-2 project-link-icon" alt="Link icon" src={LinkPNG} style={{width: "14px", height: "14px", opacity: 0.3}}/>
                </a>
            </div>
            {data.node.frontmatter.tags.map((tag) => <h5 className="tag" key={data.node.frontmatter.title.replace(" ", "-")+"-"+tag}>{tag.toUpperCase()} </h5>)}
        </div>
        <ul className="dash">
            {data.node.frontmatter.longDesc.map((desc, i) => (i < bulletLimit[projectNum] ? <li key={data.node.frontmatter.title.replace(" ", "-")+"-desc-"+i} dangerouslySetInnerHTML={{__html: desc}}></li> : null))}
        </ul>
    </div>
)

export default ({bulletLimit, projectLimit}) => (
    <StaticQuery
    query={graphql`
        query ProjectsCV {
            allMdx(filter: {frontmatter: {featuredOnCV: {eq: true}, type: {eq: "Project"}}}, sort: {fields:frontmatter___favRank}) {
                edges {
                node {
                    id
                    frontmatter {
                    title
                    path
                    longDesc
                    tags
                    }
                }
                }
            }
        }
    `}
    render={data => (
        <div className="row col-12 mx-auto section">
            <hr/>
            <h3 className="col-12 px-0">TECHNICAL PROJECTS</h3>
            {data.allMdx.edges.map((project, i) => (i < projectLimit ? <Project bulletLimit={bulletLimit} projectNum={i} data={project} key={project.node.frontmatter.title.replace(" ", "-")}/> : null ))}
        </div>
    )}
    />
)