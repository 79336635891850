import React from "react"
import "../../styles/cv.sass"
import Header from "./header"
import Skills from "./skills"
import {EducationEx, EducationEd} from "./education"
import Certifications from "./certifications"
import Achievements from "./achievements"
import Interests from "./interests"
import Intro from "./intro"
import Experience from "./experience"
import Projects from "./projects"
import core from "../../data/core.json"
import skills from "../../data/skills.json"
import education from "../../data/education.json"
import experience from "../../data/work-experience.json"
import cvData from "../../data/cv-data.json"

export default ({small, focus}) => {
  const Wrapper = ({ children }) => (
    <div
      id="cvDiv"
      className="special-div mx-auto"
      style={{
        backgroundColor: "",
        width: "209mm",
        height: "296mm",
        position: "relative",
      }}
    >
      {children}
    </div>
  )

  const ExperienceCV = () => (
    <div className="cv">
      <Header data={core} />

      <div className="row col-12 mx-0">
        <div className="col-3 px-0 column">
          <Skills data={skills} />
          <EducationEx data={education} />
          <Certifications data={cvData.certifications} />
          <Achievements data={cvData.achievements} bulletLimit={2}/>
          <Interests data={cvData.interests} bulletLimit={2}/>
        </div>

        <div className="col-9 px-0 pl-4 column">
          <Intro data={core} />
          <Experience data={experience} excludeList={[1]} bulletLimit={3} />
          <Projects projectLimit={2} bulletLimit={[2, 1, 1]} />
        </div>
      </div>
    </div>
  )

  const EducationCV = () => (
    <div className="cv">
      <Header data={core} />

      <div className="row col-12 mx-0">
        <div className="col-3 px-0 column">
          <Skills data={skills} />
          <Certifications data={cvData.certifications} />
          <Achievements data={cvData.achievements} bulletLimit={3} />
          <Interests data={cvData.interests} bulletLimit={4}/>
        </div>

        <div className="col-9 px-0 pl-4 column">
          <Intro data={core} />
          <EducationEd data={education} bulletLimit={0} />
          <Experience data={experience} excludeList={[1]} bulletLimit={1} />
          <Projects projectLimit={1} bulletLimit={[1]} />
        </div>

      </div>
    </div>
  )

  if (small) {
    return (focus === "experience" ? <ExperienceCV /> : <EducationCV />)
  } else {
    return (
      <Wrapper>
        {focus === "experience" ? <ExperienceCV /> : <EducationCV />}
      </Wrapper>
    )
  }
}
