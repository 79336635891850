import React from "react"

export default ({data, bulletLimit}) => (
  <div className="row col-12 mx-auto section">
    <hr/>
    <h3 className="col-12 px-0 text-center">ACHIEVEMENTS</h3>
    <div className="col-12 px-0">
      <ul className="dash">
        {data.map((achievement, i) => ( i < bulletLimit ? <li key={achievement.replace(" ", "-")}>{achievement}</li> : null))}
      </ul>
    </div>
  </div>
)