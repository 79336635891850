import React from "react"

export const CertificationItem = ({certification}) => (
    <div className="col-12">
        <p className="skills-body text-center"><i>{certification.name} <b>({certification.year})</b></i></p>
    </div>
)

export default ({data}) => (
    <div className="row col-12 mx-auto section">
        <hr/>
        <h3 className="col-12 px-0 text-center">CERTIFICATIONS</h3>
        <div>
            {data.map((certification) => 
                <CertificationItem certification={certification} key={certification.name.replace(" ", "-")} />
            )}
        </div>
    </div>
)