import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Cv from "../components/cv/cv"
import 'firebase/database'
import { PDFExport } from "@progress/kendo-react-pdf"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { isMobile } from "react-device-detect";

export default class CV extends React.Component {
  pdfExportComponent

  state = {
    focus: "experience"
  }

  handleSliderChange = (value) => {
    if(value === 0) {
      this.setState({focus: "experience"});
    }
    if(value === 50) {
      this.setState({focus: "mixed"});
    }
    if(value === 100) {
      this.setState({focus: "education"});
    }
  }

	render() {
		return (
      <div style={{overflowX: "hidden", backgroundColor:"#f8f9fa"}}>
        <Layout>
          <SEO title="CV" />
          <div className="container row col-md-10 col-12 mx-auto mx-0 px-0 mt-2 mb-4 d-flex">
            { isMobile ? null :
              <div className="mx-auto text-center order-sm-2" style={{paddingLeft: "0.65rem", paddingRight: "0.65rem"}} >
                <h5 className="mb-2 mt-sm-3">Prefer paper?</h5>
                <button
                  className="py-2 btn btn-primary"
                  onClick={() => {
                    this.pdfExportComponent.save()
                  }}
                  style={{ width: 220 }}
                >
                  DOWNLOAD PDF
                </button>
              </div>
            }
            <div className={`mx-auto pt-2 order-sm-1 ${(isMobile ? "mt-1" : "mt-4 mt-sm-2")}`} style={{paddingLeft: "0.65rem", paddingRight: "0.65rem"}}>
              <div  className="mx-auto w-auto" style={{minWidth:"280px", maxWidth:"300px"}}>
                <h4 className="text-center"><b>CV Focus</b></h4>
                <div className="col-10 mx-auto mt-1 mb-2">
                  <Slider step={100} dots={true} onChange={this.handleSliderChange}
                    trackStyle={{ backgroundColor: '#e9e9e9' }}
                    railStyle={{ height: "10px"}}
                    activeDotStyle={{ borderColor: '#e9e9e9' }}
                    handleStyle={{border: "solid 3px rgb(251, 47, 109)", height: "21px", width: "21px", marginLeft:"4px"}}
                    dotStyle={{height: "18px", width: "18px", bottom: "-10px"}}
                  />
                </div>
                <div className="col-12 d-flex justify-content-between">
                  <p className="mb-0 text-left">Experience</p>
                  <p className="mb-0 text-right">Education</p>
                </div>
              </div>
            </div>
          </div>

        <div className="scale">
            <div className="col-12 mobile-cv-container">
              <div className="hide-on-big mobile-cv">
                <Cv small={true} focus={this.state.focus} />
              </div>
            </div>

            <div
            className="paper px-0 col-12 mx-auto"
            style={{
              backgroundColor: "",
              maxWidth: "210mm",
              maxHeight: "297mm",
            }}>
              <PDFExport
                scale={0.75}
                paperSize="A4"
                margin="0cm"
                ref={(component) => (this.pdfExportComponent = component)}
                fileName="CV-JakeThomson"
              >
                <Cv focus={this.state.focus} />
              </PDFExport>
            </div>
          </div>
        </Layout>
      </div>
			)
	}
}