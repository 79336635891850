import React from "react"
import { format } from "date-fns"

export const Role = ({data, bulletLimit}) => (
    <div className="row col-12 mx-auto px-0">
        <p className="col-6 px-0 d-flex" style={{fontSize:"11pt"}}><b><i>{data.title}</i></b></p>
        <p className="col-6 px-0 d-flex justify-content-end" style={{fontSize:"10pt", transform: "translate(0, 2px)"}}><b>{format(new Date(data.startDate), "MMM yyyy") + " - " + (data.endDate.toLowerCase() === "present" ? "Present" : format(new Date(data.endDate), "MMM yyyy"))}</b></p>
        <ul className="dash">
            {data.longDesc.map((desc, i) => (i < bulletLimit ? <li key={data.title.replace(" ", "-")+"-desc-"+i} >{desc}</li> : null))}
        </ul>
    </div>
)

export const Company = ({data, excludeList, bulletLimit}) => {
    return (
    <div className="row col-12 mx-auto pt-2 px-0">
        <div>
            <p className="d-inline-block pr-1" style={{fontSize:"14pt"}}><b>{data.name}</b></p>
            <p className="d-inline-block contract pl-1" style={{fontSize:"13pt"}}>{data.subject}</p>
        </div>
        <p className="col-12 px-0 d-flex" style={{fontSize:"10pt", lineHeight:"100%"}}><i>{data.location}</i></p>
        <p className="col-12 px-0 pt-2 pb-1">{data.label}</p>
        {
          data.events.map((role) => !excludeList.includes(role.id) ?
            <Role bulletLimit={bulletLimit} data={role} key={data.name.replace(" ", "-")+"-"+role.title.replace(" ", "-")} />
            : null
          )
        }
    </div>
)}

export default ({data, excludeList, bulletLimit}) => (
    <div className="row col-12 mx-auto section">
        <hr/>
        <h3 className="col-12 px-0 mb-2">EXPERIENCE</h3>
        {data.filter(
            (data) => data.relevance === 1)
            .map((data) => <Company bulletLimit={bulletLimit} excludeList={excludeList} data={data} key={data.name.replace(" ", "-")} />
        )}
    </div>
)